<template>
    <div>

        <!-- Header-Image Section -->
        <section class="header-image">
            <div class="container text-center">
                <h1 class="text-white">PharmaConnect | Contactez-Nous</h1>
            </div>

        </section>

        <!-- Contact Us Section -->
        <section class="contact text-center py-5">
            <div class="container">
                <h2></h2>
                <br>
                <form @submit.prevent="sendMessage" class="mx-auto" style="max-width: 600px;">
                    <b-form-group label="Nom" label-for="name">
                        <b-form-input id="name" v-model="contactForm.name" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                        <b-form-input id="email" type="email" v-model="contactForm.email" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Message" label-for="message">
                        <b-form-textarea id="message" v-model="contactForm.message" rows="4" required></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Envoyer le Message</b-button>
                </form>
            </div>
        </section>

    </div>
</template>

<script>


export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | Contactez-Nous',
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: "Vous avez des questions ? Contactez PharmaConnect pour toute information ou assistance sur nos services et solutions pharmaceutiques."
                },
                {
                    name: 'keywords',
                    content: 'PharmaConnect, Pharmacie, Grossistes, Fournisseurs, Tunisie, Pharmaciens, Plateforme Pharmaceutique, parapharmacie,parapharmaceutique, Commandes en Ligne'
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | Contactez-Nous'
                },
                {
                    property: 'og:description',
                    content: "Vous avez des questions ? Contactez PharmaConnect pour toute information ou assistance sur nos services et solutions pharmaceutiques."
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/contact",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "PharmaConnect | Contactez-Nous",
                },
                {
                    name: "twitter:description",
                    content: "Vous avez des questions ? Contactez PharmaConnect pour toute information ou assistance sur nos services et solutions pharmaceutiques."
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/contact",
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | Contactez-Nous",
                        "description": "Vous avez des questions ? Contactez PharmaConnect pour toute information ou assistance sur nos services et solutions pharmaceutiques.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
    data() {
        return {
            contactForm: {
                name: '',
                email: '',
                message: ''
            }
        };
    },
    methods: {
        async sendMessage() {
            console.log(this.contactForm);
            await this.$http.post("contact", this.contactForm);
            this.contactForm = {
                name: '',
                email: '',
                message: ''
            };
            this.$swal({
                title: 'Message Envoyé',
                text: 'Votre message a été envoyé, il sera traité bientôt !',
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        }
    }
};
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}
</style>